import React, { useEffect, useState } from "react";

import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useNavigate, useParams } from "react-router-dom";

import classnames from "classnames";

import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import Loader from "../../components/Loader";

import InformationModal from "../../components/InformationModal";

import {
  useJobDetails,
  ACTIONS,
  TABS,
} from "../../providers/jobDetailsProvider";

import { jobsApi } from "../../services/jobServices";
import JobStatus from "../../components/admin/jobs/JobStatus";
import DetailingInfoTab from "../../components/admin/jobs/detailingInfo/DetailingInfoTab";
import OrderAcknowledgmentTab from "../../components/admin/jobs/orderAcknowledgment/OrderAcknowledgmentTab";
import ProjectDetailsTab from "../../components/admin/jobs/ProjectDetailsTab";
import QuoteItemsTab from "../../components/admin/jobs/quoteItem/QuoteItemsTab";
import QuoteRecapTab from "../../components/admin/jobs/quoteRecap/QuoteRecapTab";
import ReportsTab from "../../components/admin/jobs/reports/ReportsTab";
import ProductionHandoffTab from "../../components/admin/jobs/ProductionHandoffTab";
import { commonApi } from "../../services/commonServices";

import {
  useCommonData,
  ACTIONS as COMMON_DATA_ACTIONS,
} from "../../providers/commonDataProvider";

import EstimatedActualReport from "../../components/admin/jobs/reports/estimatedActualReport.js/EstimatedActualReport";
import JobActions from "./JobActions";
import InvoiceStatusTab from "../../components/admin/jobs/InvoiceStatusTab";
import { useAuth } from "../../providers/authProvider";
import { utils } from "../../utils/utils";
import JobModal from "../../components/admin/jobs/JobModal";

const STATUS_RFQ = 1;
const STATUS_PENDING = 3;
const STATUS_NOT_AWARDED = 6;
const STATUS_NO_QUOTE = 7;
const STATUS_ARCHIVED = 11;
const ESTIMATOR_ROLE_ID = 2;
const PROJECT_ASSISTANT_ROLE_ID = 5;

const JobDetails = () => {
  const [authContext] = useAuth();
  const navigate = useNavigate();
  const { id } = useParams();

  const [jobDetails, setJobDetails] = useJobDetails();
  const [, setCommonDataContext] = useCommonData();

  const [loading, setLoading] = useState();
  const [createJobModal, setCreateJobModal] = useState(false);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  const isAdmin = utils.isAdmin(authContext.currentUser?.role?.id);
  const isProjectAssistant =
    authContext.currentUser.role?.id === PROJECT_ASSISTANT_ROLE_ID;

  const getEstimatorTabs = () =>
    authContext.currentUser.role?.id === ESTIMATOR_ROLE_ID &&
    jobDetails.job?.estimatorId === authContext.currentUser?.id
      ? [
          <NavItem key={TABS.PROJECT_DETAILS_TAB}>
            <NavLink
              className={classnames({
                active:
                  jobDetails.activeGeneralTab === TABS.PROJECT_DETAILS_TAB,
              })}
              onClick={() => toggle(TABS.PROJECT_DETAILS_TAB)}
            >
              Job Details
            </NavLink>
          </NavItem>,
          <NavItem key={TABS.QUOTE_ITEMS_TAB}>
            <NavLink
              className={classnames({
                active: jobDetails.activeGeneralTab === TABS.QUOTE_ITEMS_TAB,
              })}
              onClick={() => toggle(TABS.QUOTE_ITEMS_TAB)}
            >
              Quote Items
            </NavLink>
          </NavItem>,
          <NavItem key={TABS.QUOTE_RECAP_TAB}>
            <NavLink
              className={classnames({
                active: jobDetails.activeGeneralTab === TABS.QUOTE_RECAP_TAB,
              })}
              onClick={() => toggle(TABS.QUOTE_RECAP_TAB)}
            >
              Quote Recap
            </NavLink>
          </NavItem>,
          <NavItem key={TABS.REPORTS_TAB}>
            <NavLink
              className={classnames({
                active: jobDetails.activeGeneralTab === TABS.REPORTS_TAB,
              })}
              onClick={() => toggle(TABS.REPORTS_TAB)}
            >
              Quote PDF
            </NavLink>
          </NavItem>,
          <NavItem key={TABS.DETAILING_INFO_TAB}>
            {isJob ? (
              <NavLink
                className={classnames({
                  active:
                    jobDetails.activeGeneralTab === TABS.DETAILING_INFO_TAB,
                })}
                onClick={() => toggle(TABS.DETAILING_INFO_TAB)}
              >
                Detailing Info
              </NavLink>
            ) : null}
          </NavItem>,
          <NavItem key={TABS.PRODUCTION_HANDOFF_TAB}>
            {jobDetails.job.statusId !== STATUS_RFQ &&
            jobDetails.job.statusId !== STATUS_PENDING ? (
              <NavLink
                className={classnames({
                  active:
                    jobDetails.activeGeneralTab === TABS.PRODUCTION_HANDOFF_TAB,
                })}
                onClick={() => toggle(TABS.PRODUCTION_HANDOFF_TAB)}
              >
                Production Handoff
              </NavLink>
            ) : null}
          </NavItem>,
          <NavItem key={TABS.ESTIMATED_VS_ACTUAL}>
            {jobDetails?.job?.statusId !== STATUS_RFQ &&
            jobDetails?.job?.statusId !== STATUS_PENDING ? (
              <NavLink
                className={classnames({
                  active:
                    jobDetails.activeGeneralTab === TABS.ESTIMATED_VS_ACTUAL,
                })}
                onClick={() => toggle(TABS.ESTIMATED_VS_ACTUAL)}
              >
                Estimated vs Actual
              </NavLink>
            ) : null}
          </NavItem>,
          <NavItem key={TABS.INVOICE_STATUS}>
            {isJob ? (
              <NavLink
                className={classnames({
                  active: jobDetails.activeGeneralTab === TABS.INVOICE_STATUS,
                })}
                onClick={() => toggle(TABS.INVOICE_STATUS)}
              >
                Invoice Status
              </NavLink>
            ) : null}
          </NavItem>,
        ]
      : [
          <NavItem key={TABS.PROJECT_DETAILS_TAB}>
            <NavLink
              className={classnames({
                active:
                  jobDetails.activeGeneralTab === TABS.PROJECT_DETAILS_TAB,
              })}
              onClick={() => toggle(TABS.PROJECT_DETAILS_TAB)}
            >
              Job Details
            </NavLink>
          </NavItem>,
          <NavItem key={TABS.ESTIMATED_VS_ACTUAL}>
            {jobDetails?.job?.statusId !== STATUS_RFQ &&
            jobDetails?.job?.statusId !== STATUS_PENDING ? (
              <NavLink
                className={classnames({
                  active:
                    jobDetails.activeGeneralTab === TABS.ESTIMATED_VS_ACTUAL,
                })}
                onClick={() => toggle(TABS.ESTIMATED_VS_ACTUAL)}
              >
                Estimated vs Actual
              </NavLink>
            ) : null}
          </NavItem>,
        ];

  const toggle = (tab) => {
    if (jobDetails.activeGeneralTab !== tab) {
      setJobDetails({
        action: ACTIONS.CHANGE_GENERAL_TAB,
        payload: {
          tab,
        },
      });
    }
  };

  // Load Job
  useEffect(() => {
    const getJob = async () => {
      setLoading(true);
      try {
        const job = await jobsApi.getJobsById(id);
        setLoading(false);
        setJobDetails({
          action: ACTIONS.GET_JOB_SUCCESS,
          payload: { job },
        });
      } catch (err) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Job Details",
          body: "There was an error with your request.",
          onClose: () => {
            setInformationModal({ isOpen: false });
            navigate("/jobs");
          },
        });
      }
    };
    getJob();
    return setJobDetails({
      action: ACTIONS.RESET,
    });
  }, [id, jobDetails.refresh, setJobDetails, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const quoteItemTypeQuoteItems = await commonApi.getQuoteItemTypes();
      const quoteItemManagementRoles = await commonApi.getManagementRoles();
      const quoteItemSubcontractorRoles =
        await commonApi.getSubcontractorRoles();
      const quoteItemShippingHandlings =
        await commonApi.getShippingHandlingOptions();
      const quoteItemLaborCategories = await commonApi.getLaborCategories();
      setCommonDataContext({
        action: COMMON_DATA_ACTIONS.GET_DATA_SUCCESS,
        payload: {
          quoteItemTypeQuoteItems,
          quoteItemManagementRoles,
          quoteItemSubcontractorRoles,
          quoteItemShippingHandlings,
          quoteItemLaborCategories,
        },
      });
      setLoading(false);
    };
    fetchData();
  }, [setCommonDataContext]);

  const isJob = utils.isTurnedIntoJob(jobDetails.job);
  return (
    <Container fluid>
      <Header className="mb-3 ">
        <HeaderTitle className="d-flex flex-column align-items-start">
          <span className="col-12 px-0 col-lg-6 text-dark">
            Job Information
          </span>
          <Button
            size="sm"
            className="rounded mt-1"
            onClick={() => navigate(-1)}
          >
            <FontAwesomeIcon icon={faChevronLeft} className="mr-1" />
            Back
          </Button>
        </HeaderTitle>
      </Header>
      <Row>
        {!jobDetails.job || loading ? (
          <Loader />
        ) : (
          <Col className="flex-grow-1">
            <Card className="py-2">
              <CardHeader className="d-flex justify-content-between pb-1">
                <div className="mr-3 flex-grow-1">
                  <div className="h4">{jobDetails.job.customer.name}</div>
                  <div>
                    <small>{jobDetails.job.customer.billingAddress}</small>
                    <br />
                    <small>
                      {jobDetails.job.customer.billingCity}
                      {jobDetails.job.customer.billingState
                        ? `, ${jobDetails.job.customer.billingState}`
                        : ""}
                    </small>
                    <br />
                    <small>{jobDetails.job.customer.billingPhone}</small>
                  </div>
                </div>
                <div className="flex-shrink-0 text-right d-flex flex-column align-items-end">
                  <div className="h4">
                    <span>
                      RFQ# {jobDetails.job.id.toString().padStart(5, "0")}
                    </span>
                  </div>
                  {!jobDetails.job.jobNumber && (
                    <div className="h4">
                      <Button
                        size="sm"
                        className="rounded mt-1"
                        color="success"
                        onClick={() => setCreateJobModal(true)}
                      >
                        Copy RFQ
                      </Button>
                    </div>
                  )}
                  {jobDetails.job.jobNumber ? (
                    <div className="h4">
                      <span>JOB# {jobDetails.job.jobNumber}</span>
                    </div>
                  ) : null}
                  {jobDetails.job.statusId === STATUS_NOT_AWARDED ? (
                    <Badge color="danger">Not Awarded</Badge>
                  ) : null}
                  {jobDetails.job.statusId === STATUS_NO_QUOTE ? (
                    <Badge color="danger">No Quote</Badge>
                  ) : null}
                  {jobDetails.job.statusId === STATUS_ARCHIVED ? (
                    <Badge color="danger">Archived</Badge>
                  ) : null}
                </div>
              </CardHeader>
              <CardBody className="pb-1 pt-0">
                <JobStatus />
              </CardBody>
            </Card>
            <Nav
              tabs
              className="d-flex text-center flex-nowrap job-details-nav cursor-pointer mt-3"
            >
              {!isAdmin && !isProjectAssistant ? (
                <div className="d-flex flex-grow-1">
                  {getEstimatorTabs()}
                  {isJob && !utils.isProd() ? (
                    <NavItem key={TABS.ORDER_ACKNOWLEDGMENT_TAB}>
                      <NavLink
                        className={classnames({
                          active:
                            jobDetails.activeGeneralTab ===
                            TABS.ORDER_ACKNOWLEDGMENT_TAB,
                        })}
                        onClick={() => toggle(TABS.ORDER_ACKNOWLEDGMENT_TAB)}
                      >
                        Order Acknowledgment
                      </NavLink>
                    </NavItem>
                  ) : null}
                </div>
              ) : (
                <div className="d-flex flex-grow-1">
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active:
                          jobDetails.activeGeneralTab ===
                          TABS.PROJECT_DETAILS_TAB,
                      })}
                      onClick={() => toggle(TABS.PROJECT_DETAILS_TAB)}
                    >
                      Job Details
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active:
                          jobDetails.activeGeneralTab === TABS.QUOTE_ITEMS_TAB,
                      })}
                      onClick={() => toggle(TABS.QUOTE_ITEMS_TAB)}
                    >
                      Quote Items
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active:
                          jobDetails.activeGeneralTab === TABS.QUOTE_RECAP_TAB,
                      })}
                      onClick={() => toggle(TABS.QUOTE_RECAP_TAB)}
                    >
                      Quote Recap
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active:
                          jobDetails.activeGeneralTab === TABS.REPORTS_TAB,
                      })}
                      onClick={() => toggle(TABS.REPORTS_TAB)}
                    >
                      Quote PDF
                    </NavLink>
                  </NavItem>
                  {isJob ? (
                    <NavItem key={TABS.ORDER_ACKNOWLEDGMENT_TAB}>
                      <NavLink
                        className={classnames({
                          active:
                            jobDetails.activeGeneralTab ===
                            TABS.ORDER_ACKNOWLEDGMENT_TAB,
                        })}
                        onClick={() => toggle(TABS.ORDER_ACKNOWLEDGMENT_TAB)}
                      >
                        Order Acknowledgment
                      </NavLink>
                    </NavItem>
                  ) : null}
                  {isJob ? (
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active:
                            jobDetails.activeGeneralTab ===
                            TABS.DETAILING_INFO_TAB,
                        })}
                        onClick={() => toggle(TABS.DETAILING_INFO_TAB)}
                      >
                        Detailing Info
                      </NavLink>
                    </NavItem>
                  ) : null}
                  {jobDetails.job.statusId !== STATUS_RFQ &&
                  jobDetails.job.statusId !== STATUS_PENDING ? (
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active:
                            jobDetails.activeGeneralTab ===
                            TABS.PRODUCTION_HANDOFF_TAB,
                        })}
                        onClick={() => toggle(TABS.PRODUCTION_HANDOFF_TAB)}
                      >
                        Production Handoff
                      </NavLink>
                    </NavItem>
                  ) : null}
                  {!isProjectAssistant &&
                  jobDetails.job.statusId !== STATUS_RFQ &&
                  jobDetails.job.statusId !== STATUS_PENDING ? (
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active:
                            jobDetails.activeGeneralTab ===
                            TABS.ESTIMATED_VS_ACTUAL,
                        })}
                        onClick={() => toggle(TABS.ESTIMATED_VS_ACTUAL)}
                      >
                        Estimated vs Actual
                      </NavLink>
                    </NavItem>
                  ) : null}
                  {isJob ? (
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active:
                            jobDetails.activeGeneralTab === TABS.INVOICE_STATUS,
                        })}
                        onClick={() => toggle(TABS.INVOICE_STATUS)}
                      >
                        Invoice Status
                      </NavLink>
                    </NavItem>
                  ) : null}
                </div>
              )}
              <JobActions />
            </Nav>
            <TabContent activeTab={jobDetails.activeGeneralTab}>
              <TabPane tabId={TABS.PROJECT_DETAILS_TAB}>
                <Card className="border-radius-0 box-shadow-none border-left border-bottom border-right">
                  <CardBody>
                    {jobDetails.activeGeneralTab ===
                    TABS.PROJECT_DETAILS_TAB ? (
                      <ProjectDetailsTab />
                    ) : null}
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId={TABS.QUOTE_ITEMS_TAB}>
                <Card className="box-shadow-none border-left border-bottom border-right">
                  <CardBody>
                    {jobDetails.activeGeneralTab === TABS.QUOTE_ITEMS_TAB ? (
                      <QuoteItemsTab />
                    ) : null}
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId={TABS.QUOTE_RECAP_TAB}>
                <Card className="box-shadow-none border-left border-bottom border-right">
                  <CardBody>
                    {jobDetails.activeGeneralTab === TABS.QUOTE_RECAP_TAB ? (
                      <QuoteRecapTab />
                    ) : null}
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId={TABS.REPORTS_TAB}>
                <Card className="box-shadow-none border-left border-bottom border-right">
                  <CardBody>
                    {jobDetails.activeGeneralTab === TABS.REPORTS_TAB ? (
                      <ReportsTab />
                    ) : null}
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId={TABS.ORDER_ACKNOWLEDGMENT_TAB}>
                <Card className="box-shadow-none border-left border-bottom border-right">
                  <CardBody>
                    {jobDetails.activeGeneralTab ===
                    TABS.ORDER_ACKNOWLEDGMENT_TAB ? (
                      <OrderAcknowledgmentTab />
                    ) : null}
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId={TABS.DETAILING_INFO_TAB}>
                <Card className="box-shadow-none border-left border-bottom border-right">
                  <CardBody>
                    {jobDetails.activeGeneralTab === TABS.DETAILING_INFO_TAB ? (
                      <DetailingInfoTab />
                    ) : null}
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId={TABS.PRODUCTION_HANDOFF_TAB}>
                <Card className="box-shadow-none border-left border-bottom border-right">
                  <CardBody>
                    {jobDetails.activeGeneralTab ===
                    TABS.PRODUCTION_HANDOFF_TAB ? (
                      <ProductionHandoffTab />
                    ) : null}
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId={TABS.ESTIMATED_VS_ACTUAL}>
                <Card className="box-shadow-none border-left border-bottom border-right">
                  <CardBody>
                    {jobDetails.activeGeneralTab ===
                    TABS.ESTIMATED_VS_ACTUAL ? (
                      <EstimatedActualReport />
                    ) : null}
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId={TABS.INVOICE_STATUS}>
                <Card className="box-shadow-none border-left border-bottom border-right">
                  <CardBody>
                    {jobDetails.activeGeneralTab === TABS.INVOICE_STATUS ? (
                      <InvoiceStatusTab />
                    ) : null}
                  </CardBody>
                </Card>
              </TabPane>
            </TabContent>
          </Col>
        )}
      </Row>
      {createJobModal && (
        <JobModal
          onClose={() => {
            setCreateJobModal(false);
          }}
          onSubmit={(id) => {
            setCreateJobModal(false);
            setInformationModal({
              isOpen: true,
              title: "Create Job",
              body: "Job created successfully.",
            });
            navigate(`/jobs/${id}/details`);
          }}
          jobData={{ ...jobDetails.job, jobId: jobDetails.job.id }}
        />
      )}
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({
                  isOpen: false,
                  title: "",
                  body: "",
                  onClose: null,
                })
          }
        />
      ) : null}
    </Container>
  );
};

export default JobDetails;
