import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { utils } from "../../../../../utils/utils";
import { Button, Col, Form, Input, ListGroup, ListGroupItem } from "reactstrap";
import {
  ACTIONS as JOB_ACTIONS,
  useJobDetails,
} from "../../../../../providers/jobDetailsProvider";
import InformationModal from "../../../../InformationModal";
import ConfirmationModal from "../../../../ConfirmationModal";

import EstimatedActualLaborSection from "./EstimatedActualLaborSection";
import EstimatedActualManagementSection from "./EstimatedActualManagementSection";
import EstimatedActualMaterialSection from "./EstimatedActualMaterialSection";
import EstimatedActualShippingHandlings from "./EstimatedActualShippingHandlings";
import EstimatedActualSubContractorsSection from "./EstimatedActualSubContractorsSection";
import EstimatedActualTotals from "./EstimatedActualTotals";

import {
  ACTIONS as QUOTE_ITEMS_ACTIONS,
  useQuoteItems,
} from "../../../../../providers/quoteItemsProvider";
import { quoteItemsApi } from "../../../../../services/quoteItemServices";
import { jobsApi } from "../../../../../services/jobServices";
import Loader from "../../../../Loader";
import {
  ACTIONS as QUOTE_RECAP_ACTIONS,
  useQuoteRecap,
} from "../../../../../providers/quoteRecapProvider";
import { evaOverrideApi } from "../../../../../services/evaOverrideServices";
import {
  useEvaOverride,
  ACTIONS as EVA_OVERRIDE_ACTIONS,
} from "../../../../../providers/evaOverrideProvider";

const EstimatedActualReport = () => {
  const [evaOverrideContext, setEvaOverrideContext] = useEvaOverride();
  const [quoteItemsContext, setQuoteItemsContext] = useQuoteItems();
  const [jobDetails, setJobDetails] = useJobDetails();
  const [loading, setLoading] = useState(false);
  const [evaLoading, setEvaLoading] = useState(false);
  const [job, setJob] = useState();
  const [quoteRecapContext, setQuoteRecapContext] = useQuoteRecap();

  const isJobLocked = jobDetails.job.lockDate;
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const doSubmit = (e) => {
    e.preventDefault();
    setConfirmationModal({
      isOpen: true,
      onSubmit: updateEVA,
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Update EVA Report",
      body: `
            <div class="text-center">
                Do you confirm you want to update EVA Report?
            </div>
      `,
      confirmColor: "danger",
    });
  };

  const updateEVA = async () => {
    setConfirmationModal(initConfirmationModal);
    setLoading(true);
    try {
      //todo do this more elegant
      const timeAttendanceLaborCodeHours = [
        ...Object.keys(evaOverrideContext.laborCategories).map(
          (laborCategoryId) => ({
            jobId: jobDetails.job.id,
            laborCategoryId,
            reg: evaOverrideContext.laborCategories[laborCategoryId],
          })
        ),
        ...Object.keys(evaOverrideContext.managementRoles).map(
          (managementRoleId) => ({
            jobId: jobDetails.job.id,
            managementRoleId,
            reg: evaOverrideContext.managementRoles[managementRoleId],
          })
        ),
      ];
      const teklaMaterials = [
        ...Object.keys(evaOverrideContext.quoteItemTypes).map(
          (quoteItemTypeId) => ({
            jobId: jobDetails.job.id,
            quoteItemTypeId,
            cost: evaOverrideContext.quoteItemTypes[quoteItemTypeId],
          })
        ),
        ...Object.keys(evaOverrideContext.subcontractorRoles).map(
          (subcontractorRoleId) => ({
            jobId: jobDetails.job.id,
            subcontractorRoleId,
            cost: evaOverrideContext.subcontractorRoles[subcontractorRoleId],
          })
        ),
        ...Object.keys(evaOverrideContext.shippingHandlingOptions).map(
          (shippingHandlingOptionId) => ({
            jobId: jobDetails.job.id,
            shippingHandlingOptionId,
            cost: evaOverrideContext.shippingHandlingOptions[
              shippingHandlingOptionId
            ],
          })
        ),
      ];
      await jobsApi.updateJob({
        ...jobDetails.job,
        evaNotes: job.evaNotes,
      });
      await evaOverrideApi.updateTAOverride({
        ...jobDetails.job,
        timeAttendanceLaborCodeHours,
      });
      await evaOverrideApi.updateTeklaDataOverride({
        ...jobDetails.job,
        teklaMaterials,
      });
      setLoading(false);
      return setInformationModal({
        isOpen: true,
        title: "Update EVA",
        body: "EVA Updated Successfully",
        onClose: () => {
          setInformationModal({ isOpen: false });
          setJobDetails({
            action: JOB_ACTIONS.REFRESH,
          });
        },
      });
    } catch (err) {
      setLoading(false);
      return setInformationModal({
        isOpen: true,
        title: "Update EVA",
        body:
          err?.response?.data[0].msg || "There was an error with your request.",
      });
    }
  };

  useEffect(() => {
    const fetchQuoteItems = async () => {
      const quoteItems = await quoteItemsApi.getQuoteItems({
        jobId: jobDetails.job.id,
      });
      setQuoteItemsContext({
        action: QUOTE_ITEMS_ACTIONS.GET_QUOTE_ITEMS_SUCCESS,
        payload: { quoteItems },
      });
    };

    if (jobDetails.job) {
      fetchQuoteItems();
      setJob(jobDetails.job);
    }
  }, [jobDetails, setQuoteItemsContext]);

  useEffect(() => {
    if (quoteItemsContext.quoteItems) {
      setQuoteRecapContext({
        action: QUOTE_RECAP_ACTIONS.ON_DO_CALCULATIONS,
        payload: {
          quoteItems: quoteItemsContext.quoteItems,
          job: jobDetails.job,
        },
      });
    }
  }, [quoteItemsContext, jobDetails, setQuoteRecapContext]);

  useEffect(() => {
    setEvaOverrideContext({
      action: EVA_OVERRIDE_ACTIONS.INIT,
      payload: {
        timeAttendanceLaborCodeHoursOverride:
          jobDetails.job.timeAttendanceLaborCodeHoursOverride,
        teklaMaterialDataOverride: jobDetails.job.teklaMaterialDataOverride,
      },
    });
  }, [jobDetails, setEvaOverrideContext]);

  const generateEvaPdf = async () => {
    setEvaLoading(true);
    const EvaData = {
      jobId: jobDetails.job.id,
      laborCategories: jobDetails.job.laborCategories,
      laborCategoryActualTotals: jobDetails.job.laborCategoryActualTotals,
      laborCategoryEstimatedHoursTotal:
        jobDetails.job.laborCategoryEstimatedHoursTotal,
      managementRoles: jobDetails.job.managementRoles,
      managementRoleActualTotals: jobDetails.job.managementRoleActualTotals,
      managementRoleEstimatedHoursTotal:
        jobDetails.job.managementRoleEstimatedHoursTotal,
      quoteItemTypeQuoteItems: jobDetails.job.quoteItemTypeQuoteItems,
      quoteItemTypesOverride: jobDetails.job.quoteItemTypesOverride,
      quoteItemsTotalActual: utils.formatCurrency(
        jobDetails.job.quoteItemsTotalActual
      ),
      quoteItemsTotalCost: utils.formatCurrency(
        jobDetails.job.quoteItemsTotalCost
      ),
      quoteItemsDifferenceTotal: jobDetails.job.quoteItemsDifferenceTotal,
      shippingHandlingOptions: jobDetails.job.shippingHandlingOptions,
      shippingHandlingOptionsOverride:
        jobDetails.job.shippingHandlingOptionsOverride,
      shippingHandlingOptionsTotalActual: utils.formatCurrency(
        jobDetails.job.shippingHandlingOptionsTotalActual
      ),
      shippingHandlingOptionsTotalCost: utils.formatCurrency(
        jobDetails.job.shippingHandlingOptionsTotalCost
      ),
      shippingHandlingOptionsDifferenceTotal:
        jobDetails.job.shippingHandlingOptionsDifferenceTotal,
      subcontractorRoles: jobDetails.job.subcontractorRoles,
      subcontractorRolesOverride: jobDetails.job.subcontractorRolesOverride,
      subcontractorRolesTotalActual: utils.formatCurrency(
        jobDetails.job.subcontractorRolesTotalActual
      ),
      subcontractorRolesTotalCost: utils.formatCurrency(
        jobDetails.job.subcontractorRolesTotalCost
      ),
      subcontractorRolesDifferenceTotal:
        jobDetails.job.subcontractorRolesDifferenceTotal,
      quoteRecap: quoteRecapContext,
      actualData: jobDetails.job.actualData,
    };

    const report = await jobsApi.evaReport(EvaData);
    const reportData = new Blob([report], { type: "application/pdf" });
    setEvaLoading(false);
    const URL = window.URL.createObjectURL(reportData);
    window.open(URL, "new");
  };

  const pdfButton = (
    <Button
      color="tertiary"
      size="sm"
      className="rounded"
      onClick={generateEvaPdf}
    >
      <span className="font-weight-bold">PDF</span>
      <FontAwesomeIcon icon={faDownload} className="ml-1" />
    </Button>
  );

  return (
    <>
      {informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal(initConfirmationModal)
          }
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
      {loading ? (
        <Loader />
      ) : (
        <Col className="mt-3 col-12">
          {isJobLocked ? (
            <div className="d-flex align-items-start justify-content-end">
              <div className="mr-3" style={{ width: evaLoading ? 50 : null }}>
                {evaLoading ? <Loader size={"sm"} align="end" /> : pdfButton}
              </div>
              <div className="px-1 py-0 bg-danger text-white">Locked</div>
            </div>
          ) : (
            <div className="d-flex justify-content-end">
              {evaLoading ? <Loader size={"sm"} align="end" /> : pdfButton}
            </div>
          )}
          <EstimatedActualLaborSection />
          <EstimatedActualManagementSection />
          <EstimatedActualMaterialSection />
          <EstimatedActualSubContractorsSection />
          <EstimatedActualShippingHandlings />
          <EstimatedActualTotals />
          <Form onSubmit={doSubmit}>
            <ListGroup className="mt-4">
              <ListGroupItem className="d-flex justify-content-center align-items-center bg-lighter">
                Notes
              </ListGroupItem>
              <ListGroupItem className="d-flex justify-content-between align-items-center p-0">
                <Input
                  className="border-0"
                  placeholder="Enter eva notes..."
                  type="textarea"
                  rows={7}
                  maxLength="4096"
                  name="evaNotes"
                  value={job?.evaNotes || ""}
                  onChange={(e) =>
                    setJob({
                      ...job,
                      evaNotes: e.target.value,
                    })
                  }
                />
              </ListGroupItem>
            </ListGroup>
            <div className="d-flex justify-content-end">
              <Button className="mt-3" color={"primary"} type="submit">
                Save
              </Button>
            </div>
          </Form>
        </Col>
      )}
    </>
  );
};

export default EstimatedActualReport;
